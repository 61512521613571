import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../store";
import type {
  LoginResponse,
  LoginCredentials,
  ErrorResponse,
} from "./authActions";
import { login, checkLogin, logout } from "./authActions";
import {
  recheckEmailVerification,
  resendEmailVerification,
} from "../check-email-verification/checkEmailVerificationActions";

const initialState = {
  email: "",
  isLoggedIn: false,
  scopes: [] as string[],
  loginMessage: "",
  profile: {
    firstName: "",
    lastName: "",
    mobilePhone: "",
    createdAt: "",
  },
  authentication: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    isRegistrationComplete: false,
    message: "",
  },
  // registrationAuthorization: {
  //   isRegistrationAuthorized: false,
  //   message: "Checking...",
  //   isLoading: false,
  //   isError: false,
  //   lastEnteredAgentEmail: "",
  //   isAtMaxChangeAgentEmailAttempts: false,
  //   hasCheckedRegistrationAuthorization: false,
  // },
  emailVerification: {
    isEmailVerified: false,
    isVerificationSent: false,
    message: "",
    isResendLoading: false,
    isRecheckLoading: false,
    isError: false,
    hasCheckedEmailVerification: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.authentication.isLoading = false;
      state.authentication.isSuccess = false;
      state.authentication.isError = false;
      state.authentication.message = "";
      state.emailVerification.isVerificationSent = false;
      state.emailVerification.isEmailVerified = false;
      state.emailVerification.message = "";
      state.emailVerification.isResendLoading = false;
      state.emailVerification.isRecheckLoading = false;
      state.emailVerification.isError = false;
      state.emailVerification.hasCheckedEmailVerification = false;
      // state.loginMessage = "";
    },
    // logoutUser: (state) => initialState,
    setLoginMessage: (state, action) => {
      state.loginMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        console.debug("status: pending");
        state.authentication.isLoading = true;
        state.authentication.isError = false;
        state.authentication.isSuccess = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        console.debug("state:");
        console.debug(state);
        console.debug("action:");
        console.debug(action);
        if (action.payload.status === "success") {
          console.debug("setting state loginUser...");
          state.email = action.payload.email;
          state.isLoggedIn = true;
          state.scopes = action.payload.scopes;
          state.emailVerification.isEmailVerified =
            action.payload.email_verified;
          state.authentication.isLoading = false;
          state.authentication.isSuccess = true;
          state.authentication.isError = false;
        }
      })
      .addCase(login.rejected, (state, action) => {
        console.debug("status: login rejected");
        state.authentication.isLoading = false;
        state.authentication.isSuccess = false;
        state.authentication.isError = true;
        state.authentication.message = (
          action.payload as ErrorResponse
        ).user_message;
      })
      .addCase(checkLogin.pending, (state, action) => {
        console.debug("status: pending");
        state.authentication.isLoading = true;
        state.authentication.isError = false;
        state.authentication.isSuccess = false;
        state.authentication.message = "Checking for existing session...";
      })
      .addCase(checkLogin.fulfilled, (state, action) => {
        console.debug("state:");
        console.debug(state);
        console.debug("action:");
        console.debug(action);
        if (action.payload.status === "success") {
          console.debug("setting state loginUser...");
          state.email = action.payload.email;
          state.isLoggedIn = true;
          state.scopes = action.payload.scopes;
          state.emailVerification.isEmailVerified =
            action.payload.email_verified;
          state.authentication.isLoading = false;
          state.authentication.isSuccess = true;
          state.authentication.isError = false;
          state.authentication.message = "";
        }
      })
      .addCase(checkLogin.rejected, (state, action) => {
        console.debug("status: login rejected");
        state.authentication.isLoading = false;
        state.authentication.isSuccess = false;
        // state.authentication.isError = true;
        // state.authentication.message = (
        //   action.payload as ErrorResponse
        // ).user_message;
        state.authentication.message = "Session expired. Please log in again.";
      })
      .addCase(logout.pending, (state, action) => {
        console.debug("status:logout pending");
        state.authentication.isLoading = true;
        state.authentication.isError = true;
        state.authentication.isSuccess = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        console.debug("status: logout fulfilled");
        // state = { ...initialState };
        return initialState;
      })
      .addCase(logout.rejected, (state, action) => {
        console.debug("status: logout rejected");
        state.authentication.isLoading = false;
        state.authentication.isSuccess = false;
        state.authentication.isError = true;
        state.authentication.message = (
          action.payload as ErrorResponse
        ).user_message;
      })

      // Email verification
      .addCase(recheckEmailVerification.pending, (state, action) => {
        console.debug("status: recheckEmailVerification pending");
        state.emailVerification.isRecheckLoading = true;
        state.emailVerification.isError = false;
      })
      .addCase(recheckEmailVerification.fulfilled, (state, action) => {
        console.debug("status: recheckEmailVerification fulfilled");
        state.emailVerification.isEmailVerified = action.payload.verified;
        state.emailVerification.hasCheckedEmailVerification = true;
        state.emailVerification.isRecheckLoading = false;
        state.emailVerification.isError = false;
        if (action.payload.verified) {
          state.emailVerification.message = "Email successfully verified!";
        } else {
          state.emailVerification.message =
            "Email is still unverified. Please verify email and check again.";
        }
      })
      .addCase(recheckEmailVerification.rejected, (state, action) => {
        console.debug("status: recheckEmailVerification rejected");
        state.emailVerification.isRecheckLoading = false;
        state.emailVerification.hasCheckedEmailVerification = true;
      })
      .addCase(resendEmailVerification.pending, (state, action) => {
        console.debug("status: resendEmailVerification pending");
        state.emailVerification.isResendLoading = true;
      })
      .addCase(resendEmailVerification.fulfilled, (state, action) => {
        console.debug("status: resendEmailVerification fulfilled");
        state.emailVerification.isResendLoading = false;
        state.emailVerification.isVerificationSent = true;
      })
      .addCase(resendEmailVerification.rejected, (state, action) => {
        console.debug("status: resendEmailVerification rejected");
        state.emailVerification.isResendLoading = false;
        state.emailVerification.isVerificationSent = false;
      });
  },
});

// export const { reset, setUserType, setAgentAuthorizedProducer } =
export const { reset, setLoginMessage } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;
export const selectEmailVerification = (state: RootState) =>
  state.auth.emailVerification;

export default authSlice.reducer;
