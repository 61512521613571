import { ChangeEvent } from "react";
import { FormEvent } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { partial } from "lodash-es";
import { useAppSelector, useAppDispatch } from "../hooks/redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Navbar from "../components/navbar/Navbar";

import { Counter } from "../components/counter/Counter";
import logo from "../logo.svg";

function DashboardScreen() {
  return (
    <div className="App">
      <div className="container-fluid bg-theme" style={{ height: "100%" }}>
        <div className="row h-100">
          <div className="d-flex flex-column align-items-center justify-content-start">
            <div className="w-100 mb-2">
              <Navbar />
              {/*<NavbarTabs />*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardScreen;
