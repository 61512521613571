// import authService, {
//   resetValues,
//   authCredentials,
//   authCredentialsProducer,
// } from "./authService";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ax from "../../config/axios";
import { AxiosResponse, AxiosError } from "axios";

// Types
export type LoginResponse = {
  console_message_frontend?: string;
  created_at?: string;
  email: string;
  error?: string;
  error_code?: number;
  first_name?: string;
  is_authorized?: boolean;
  email_verified: boolean;
  last_name?: string;
  mobile_phone?: string;
  permissions?: string[];
  scopes: string[];
  status: string;
  status_code?: number;
  user_message?: string;
};

export type ErrorResponse = {
  console_message_frontend?: string;
  error?: string;
  error_code?: number;
  status: string;
  status_code: number;
  user_message: string;
};

// Interfaces
export interface LoginCredentials {
  email: string;
  password: string;
}

export interface ErrorWithResponse extends Error {
  response?: {
    data?: {
      code?: string;
    };
  };
}

export interface ResetValues {
  rcode: string;
  email: string;
  new_password: string;
}

const UNKNOWN_ERROR = {
  status: "Unknown",
  user_message:
    "Unknown error occurred. Please try again later or contact support.",
};

const CONNECTION_ERROR = {
  status: "Unknown",
  user_message:
    "Connection failed. The server may be unavailable or your internet connection may be down.",
};

export const login = createAsyncThunk(
  "auth/login",
  async (
    userCredentials: LoginCredentials,
    thunkAPI,
  ): Promise<LoginResponse> => {
    try {
      const result = await ax.post("auth/login", {
        username: userCredentials.email,
        password: userCredentials.password,
      });
      switch (result.status) {
        case 200:
          console.info(200);
          // result.data should be LoginResponse
          if (result.data.console_message_frontend) {
            console.info(result.data.console_message_frontend);
          }
          return result.data;
        default:
          console.debug("login default");
          return result.data;
      }
    } catch (error: any) {
      console.debug("login error:");
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        thunkAPI.rejectWithValue(CONNECTION_ERROR);
      }
      switch (error.response?.status) {
        case 400:
          console.debug(400);
          throw thunkAPI.rejectWithValue(error.response?.data);
        case 401:
          console.debug(401);
          throw thunkAPI.rejectWithValue(error.response?.data);
        default:
          throw thunkAPI.rejectWithValue(UNKNOWN_ERROR);
      }
    } finally {
      // console.log("finally");
    }
  },
);

export const checkLogin = createAsyncThunk(
  "auth/checkLogin",
  async (_: void, thunkAPI) => {
    try {
      // Try to login
      // no params needed because cookie should be sent automatically
      const result = await ax.post("auth/check-login");

      switch (result.status) {
        case 200:
          // auto login was successful
          console.info("auto-login successful");
          console.info(result.data.console_message_frontend);
          return result.data;
        default:
          console.debug("checkLogin default case");
          return result.data;
      }
    } catch (error: any) {
      console.debug("checkLogin error:");
      console.error(error);
      if (error.code === "ERR_NETWORK") {
        thunkAPI.rejectWithValue(CONNECTION_ERROR);
      }
      switch (error.response?.status) {
        case 400:
          console.debug(400);
          throw thunkAPI.rejectWithValue(error.response?.data);
        case 401:
          console.debug(401);
          throw thunkAPI.rejectWithValue(error.response?.data);
        default:
          throw thunkAPI.rejectWithValue(UNKNOWN_ERROR);
      }
    } finally {
      // console.log("finally");
    }
  },
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (callback: Function = () => {}, thunkAPI): Promise<LoginResponse> => {
    try {
      const result: AxiosResponse = await ax.post("auth/logout");
      switch (result.status) {
        case 200:
          console.info(200);
          // result.data should be LoginResponse
          if (result.data.console_message_frontend) {
            console.info(result.data.console_message_frontend);
          }
          callback();
          return result.data;
        default:
          console.debug("logout default");
          callback();
          return result.data;
      }
    } catch (error: any) {
      console.debug("logout error:");
      console.log(error);
      if ((error as AxiosError).code === "ERR_NETWORK") {
        thunkAPI.rejectWithValue(CONNECTION_ERROR);
      }
      switch (error.response?.status) {
        case 400:
          console.debug(400);
          throw thunkAPI.rejectWithValue(error.response?.data);
        case 401:
          console.debug(401);
          throw thunkAPI.rejectWithValue(error.response?.data);
        default:
          throw thunkAPI.rejectWithValue(UNKNOWN_ERROR);
      }
    } finally {
      // console.log("finally");
    }
  },
);

export const requestPasswordReset = createAsyncThunk(
  "auth/requestPasswordReset",
  async (email: string, thunkAPI) => {
    try {
      const response = await ax.post("auth/send-password-reset", {
        email: email,
      });
      const payload = await response.data;
      console.log(payload);
      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(
          payload.error
            ? payload.error
            : "Unable to send reset email. Please contact support.",
        );
      }

      return payload;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        "Unable to send reset email. Please contact support.",
      );
    }
  },
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (resetValues: ResetValues, thunkAPI) => {
    try {
      // const response = await authService.doPasswordReset(
      //   resetValues.email,
      //   resetValues.rcode,
      //   resetValues.new_password,
      // );

      const response = await ax.post("auth/password-reset-finalize", {
        email: resetValues.email,
        r_code: resetValues.rcode,
        password: resetValues.new_password,
      });

      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      }

      if (response.status === 400) {
        const errorMessage = payload.error;
        throw errorMessage;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to reset password. Please contact support.",
        );
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        "Unable to reset password. Please contact support.",
      );
    }
  },
);

export const getPermissions = createAsyncThunk(
  "auth/getPermissions",
  async (_: void, thunkAPI: any) => {
    try {
      const response = await ax.post("auth/check-permissions", {});
      const payload = await response.data;
      if (response.status === 200) {
        if (payload.hasOwnProperty("permissions")) {
          return payload.permissions;
        } else {
          return thunkAPI.rejectWithValue("Unable to get permssions.");
        }
      } else {
        return thunkAPI.rejectWithValue("Unable to get permssions.");
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue("Unable to get permssions.");
    }
  },
);
