// import { ChangeEvent } from "react";
// import { FormEvent } from "react";
import { Link } from "react-router-dom";
// import { partial } from "lodash-es";
// import { useAppSelector, useAppDispatch } from "../../hooks/redux";
// import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useState } from "react";

function BSNavLink(props: {
  scopes: string[];
  scope: string;
  href: string;
  text: string;
}) {
  const navigate = useNavigate();
  const { scopes, scope, href, text } = props;

  const handleClick = () => {
    console.debug(`navigate(${href})`);
    navigate(href);
  };
  return (
    <>
      {scopes.includes(scope) && (
        <li className="nav-item">
          <a
            className={
              "nav-link" + (location.pathname.includes(scope) ? " active" : "")
            }
            href=""
            onClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
          >
            {text}
          </a>
        </li>
      )}
    </>
  );
}

export default BSNavLink;
