import React, { useEffect, useState, FormEvent, ChangeEvent } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { selectAuth, setLoginMessage } from "../auth/authSlice";
import { selectPasswordResetFinalize } from "./passwordResetSlice";
import { resetFinalize } from "./passwordResetActions";
import validatePasswords from "../validators/passwordRegistrationValidator";
import useMounted from "../../hooks/useMounted";
// import "../authStyles.scss";

import company_logo from "../../assets/csc_logo_transparent.png";
import styles from "./PasswordReset.module.css";

function PasswordResetFinalize(props: { loginUrl: string; exitUrl: string }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, isPasswordResetSent, message } = useAppSelector(
    selectPasswordResetFinalize,
  );

  const [recoveryCode, setRecoveryCode] = useState("");
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>(
    [],
  );
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [password1Touched, setPassword1Touched] = useState(false);
  const [password2Touched, setPassword2Touched] = useState(false);
  const [formValid, setFormValid] = useState(false);
  // const [formTouched, setFormTouched] = useState(false);

  const [searchParams] = useSearchParams();
  const isMounted = useMounted();

  const resetState = () => {
    setRecoveryCode("");
    setRecoveryEmail("");
    setErrorMessage("");
    setSuccessMessage("");
    setPasswordErrorMessages([]);
    setPassword1(""); // new password
    setPassword2(""); // confirm password
    setSubmitDisabled(true);
    setPassword1Touched(false);
    setPassword2Touched(false);
    setFormValid(false);
    // setFormTouched(false);
  };

  // Reset on navigate
  useEffect(() => {
    // dispatch(resetAuth());
    resetState();
  }, [navigate]);

  // Get r_code and email from url params, if not found then throw error
  useEffect(() => {
    // dispatch(resetAuth());
    const r_code = searchParams.get("rcode");
    const email = searchParams.get("email");
    if (r_code && email) {
      setRecoveryCode(r_code);
      setRecoveryEmail(email);
    } else {
      setErrorMessage(
        "Recovery link is missing recovery code and/or email. " +
          "You will be unable to reset your password without them.",
      );
    }
  }, [dispatch, searchParams]);

  // Validate passwords on input
  useEffect(() => {
    if (isMounted) {
      const password_validation = validatePasswords(password1, password2);

      if (password_validation.valid) {
        setFormValid(true);
        setPasswordErrorMessages([]);
      } else {
        setFormValid(false);
        setPasswordErrorMessages(password_validation.messages);
      }
    }
  }, [password1, password2, isMounted]);

  // Check form validity, enable submit button if form is valid
  useEffect(() => {
    if (formValid) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [formValid]);

  useEffect(() => {
    if (!isLoading && !isError && isPasswordResetSent) {
      dispatch(setLoginMessage(message));
      navigate(props.exitUrl);
    }
    if (isError && message) {
      setErrorMessage(message);
    }
  }, [isLoading, isError, isPasswordResetSent]);

  const createOnChange = (fn: Function) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // Update credentials in state
      fn(e.target.value);
    };
    return onChange;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage("");
    const password_validation = validatePasswords(password1, password2);
    if (password_validation.valid) {
      dispatch(
        resetFinalize({
          email: recoveryEmail,
          rcode: recoveryCode,
          new_password: password1,
        }),
      );
    } else {
      setPasswordErrorMessages(password_validation.messages);
    }
  };

  return (
    <div className="card mx-auto auth-card">
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={company_logo}
            alt="CSC AG Logo"
            className={"mt-2 mb-4 mx-auto " + styles.companyLogo}
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">Reset Your Password</h5>
        </div>
        <div className="mt-2">
          <form onSubmit={onSubmit}>
            <div className="form-group text-left small">
              <div>
                <p className="info-text">Minimum Password Requirements:</p>
                <ul className="info-list">
                  <li>At least 10 characters</li>
                  <li>Contains both UPPERCASE and lowercase letters</li>
                </ul>
              </div>
              <label htmlFor="password1" className="form-label">
                New Password
              </label>
              <input
                className="form-control"
                type="password"
                value={password1}
                name="password1"
                id="password1"
                placeholder="password"
                onChange={createOnChange(setPassword1)}
                autoComplete="off"
                onBlur={() => setPassword1Touched(true)}
              />
            </div>
            <div className="form-group text-left small mt-2">
              <label htmlFor="password2" className="form-label">
                Confirm Password
              </label>
              <input
                className="form-control"
                type="password"
                value={password2}
                name="password2"
                id="password2"
                placeholder="password"
                onChange={createOnChange(setPassword2)}
                autoComplete="off"
                onBlur={() => setPassword2Touched(true)}
              />
            </div>
            <ul className="error-list">
              {password1Touched &&
                password2Touched &&
                passwordErrorMessages.map((error: string, index: number) => (
                  <li key={index} className="text-danger">
                    {error}
                  </li>
                ))}
            </ul>
            <div className="text-center">
              <button
                disabled={submitDisabled || isLoading}
                type="submit"
                className="btn btn-primary w-75"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Reset Password</>
                )}
              </button>
            </div>
          </form>
        </div>
        {errorMessage && errorMessage !== "" && (
          <div className="text-danger text-center mt-4">
            <p>{errorMessage}</p>
          </div>
        )}
        {successMessage && successMessage !== "" && (
          <div className="text-success text-center mt-4">
            <p>{successMessage}</p>
          </div>
        )}
        <div className="mt-4 w-100 small text-center">
          <Link style={{ textDecoration: "none" }} to="/login">
            <small>{"<< Back to Login"}</small>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetFinalize;
