import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";

import React from "react";

function ProtectedRoutes() {
  const { email, isLoggedIn } = useAppSelector((state) => state.auth);
  const { isEmailVerified } = useAppSelector(
    (state) => state.auth.emailVerification,
  );

  const _authorized = email && isLoggedIn && isEmailVerified;

  return _authorized ? <Outlet /> : <Navigate to="/manage-account" />;
}

export default ProtectedRoutes;
