import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../store";
import {
  resetFinalize,
  resetRequest,
  ResetRequestResponse,
  ResetFinalizeResponse,
} from "./passwordResetActions";

const initialState = {
  passwordResetRequest: {
    isLoading: false,
    isError: false,
    isPasswordResetSent: false,
    message: "",
  },
  passwordResetFinalize: {
    isLoading: false,
    isError: false,
    isPasswordResetSent: false,
    message: "",
  },
};

export const passwordReset = createSlice({
  name: "passwordReset",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetRequest.pending, (state, action) => {
        console.debug("status: resetRequest pending");
        state.passwordResetRequest.isLoading = true;
        state.passwordResetRequest.isError = false;
      })
      .addCase(resetRequest.fulfilled, (state, action) => {
        console.debug("status: resetRequest fulfilled");
        state.passwordResetRequest.isLoading = false;
        state.passwordResetRequest.isError = false;
        state.passwordResetRequest.isPasswordResetSent = true;
        state.passwordResetRequest.message =
          "Password reset email sent successfully. Please be sure to check " +
          "your Spam folder. Please follow the link in the email to reset " +
          "your password.";
      })
      .addCase(resetRequest.rejected, (state, action) => {
        console.debug("status: resetRequest rejected");
        state.passwordResetRequest.isLoading = false;
        state.passwordResetRequest.isError = true;
        state.passwordResetRequest.message = (
          action.payload as ResetRequestResponse
        ).user_message;
      })
      .addCase(resetFinalize.pending, (state, action) => {
        console.debug("status: resetFinalize pending");
        state.passwordResetFinalize.isLoading = true;
        state.passwordResetFinalize.isError = false;
      })
      .addCase(resetFinalize.fulfilled, (state, action) => {
        console.debug("status: resetFinalize fulfilled");
        state.passwordResetFinalize.isLoading = false;
        state.passwordResetFinalize.isError = false;
        state.passwordResetFinalize.isPasswordResetSent = true;
        state.passwordResetFinalize.message =
          "Your password has been successfully reset. Please log in with " +
          "your new password.";
      })
      .addCase(resetFinalize.rejected, (state, action) => {
        console.debug("status: resetFinalize rejected");
        state.passwordResetFinalize.isLoading = false;
        state.passwordResetFinalize.isError = true;
        state.passwordResetFinalize.message = (
          action.payload as ResetFinalizeResponse
        ).user_message;
      });
  },
});

export const selectPasswordResetRequest = (state: RootState) =>
  state.passwordReset.passwordResetRequest;
export const selectPasswordResetFinalize = (state: RootState) =>
  state.passwordReset.passwordResetFinalize;

export default passwordReset.reducer;
