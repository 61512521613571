import BSTextInput from "../forms/BSTextInput";
import logoUrl from "../../assets/csc_logo_transparent.png";
import styles from "./PasswordReset.module.css";
import useMounted from "../../hooks/useMounted";
// import { partial } from "lodash-es";
import {
  selectAuth,
  reset as resetAuth,
  setLoginMessage,
} from "../auth/authSlice";

import { selectPasswordResetRequest } from "./passwordResetSlice";
// import { doLogin } from "../login/loginService";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect, ChangeEvent, FormEvent, useRef } from "react";
import validateEmail from "../validators/emailValidator";
import validatePasswords from "../validators/passwordRegistrationValidator";
import { resetRequest } from "./passwordResetActions";

export function Register(props: { loginUrl: string; exitUrl: string }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { email, isLoggedIn } = useAppSelector(selectAuth);
  const { isLoading, isError, isPasswordResetSent, message } = useAppSelector(
    selectPasswordResetRequest,
  );

  const emailRef = useRef<HTMLInputElement>(null);
  const loginRef = useRef<HTMLAnchorElement>(null);

  const [formEmail, setFormEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const isMounted = useMounted();

  // Reset on navigate
  useEffect(() => {
    // dispatch(resetAuth());
    dispatch(setLoginMessage(""));
    setFormEmail("");
    setEmailError("");
    setGeneralError("");
    setEmailValid(false);
    setEmailTouched(false);
    setSubmitDisabled(false);
  }, [navigate, dispatch]);

  // set focus to "back to login" link after successful reset request
  useEffect(() => {
    if (isMounted) {
      if (!isError) {
        setGeneralError("");
      }
      if (loginRef.current && message !== "") {
        // setTimeout(() => {
        //   console.debug("setting focus useEffect...");
        //   loginRef.current?.focus();
        // }, 500);
        loginRef.current?.focus();
      }
    }
  }, [message]);

  // redirect to exit screen
  useEffect(() => {
    if (isLoggedIn && email) {
      // If success or email in state then redirect to loading page
      navigate(props.exitUrl);
    }
  }, [email, isLoggedIn, navigate]);

  // validate form
  useEffect(() => {
    setSubmitDisabled(!emailValid);
  }, [emailValid]);

  // Validate email
  useEffect(() => {
    if (isMounted) {
      const email_validation = validateEmail(formEmail);
      if (email_validation.valid) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
      setEmailError(email_validation.message);
    }
    // we are not adding isMounted to the dependency array as it would result
    // in error message being shown early
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formEmail]);

  useEffect(() => {
    if (isPasswordResetSent && !isError) {
      setSubmitDisabled(true);
      setGeneralError("");
    }
  }, [isPasswordResetSent, isError]);

  const createOnChange = (fn: Function) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // Update credentials in state
      fn(e.target.value);
    };
    return onChange;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // re-check email/password validation

    // display message to user if invalid; set focus to invalid item

    // submit to backend if valid

    const emailValidationResult = validateEmail(formEmail);
    if (emailValidationResult.valid) {
      // dispatch(loginUser(userCredentials));
      setGeneralError("");
      dispatch(resetRequest(formEmail));
    } else {
      setEmailError(emailValidationResult.message);
    }
  };

  return (
    <div
      className={"card mx-auto auth-card w-100 " + styles.loginContainer}
      // style={{ minWidth: "300px" }}
    >
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={logoUrl}
            alt="AG Logo"
            className={"mt-2 mb-4 mx-auto " + styles.companyLogo}
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">Reset Your Password</h5>
        </div>
        <div className="mt-2 text-left">
          <form onSubmit={onSubmit}>
            <div className="form-group text-center small mx-auto w-100 my-4">
              <div>
                <p className="info-text">
                  Enter your registered email address and we will send you
                  instructions on how to recover your account.
                </p>
              </div>
            </div>
            <BSTextInput
              ref={emailRef}
              label={"Email Address"}
              type={"email"}
              onChange={createOnChange(setFormEmail)}
              name={"email"}
              value={formEmail}
              touched={emailTouched}
              message={emailError}
              required={false}
              placeholder={"someone@example.com"}
              id={"email"}
              onBlur={() => setEmailTouched(true)}
            />

            <div className="text-center">
              <button
                className="btn btn-primary w-75 mt-4"
                disabled={submitDisabled || isLoading}
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Continue</>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="text-center mt-4">
          {generalError && generalError !== "" && (
            <div className="text-danger">{generalError}</div>
          )}
        </div>
        <div className="text-center mt-4">
          {message && message !== "" && (
            <div className="text-success">{message}</div>
          )}
        </div>
        <div className="mt-4 w-100 text-center">
          <small>
            <Link
              style={{ textDecoration: "none" }}
              className={styles.ensureFocus}
              to={props.loginUrl}
              ref={loginRef}
            >
              &lt;&lt; Return to Login
            </Link>{" "}
          </small>
        </div>
      </div>
    </div>
  );
}

export default Register;
