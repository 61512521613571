interface ValidationResult {
  valid: boolean;
  messages: string[];
}

interface RuleResult {
  passed: boolean;
  message: string;
}

const createPasswordValidator = (
  rules: { (p1: string, p2: string): RuleResult }[],
) => {
  return (password1: string, password2: string) => {
    let passwordErrors: string[] = [];

    // check all rules
    for (const rule in rules) {
      const result = rules[rule](password1, password2);
      if (!result.passed) {
        passwordErrors.push(result.message);
      }
    }
    if (passwordErrors.length === 0) {
      // If no errors then valid
      return {
        valid: true,
        messages: [],
      };
    }

    // Else, invalid
    return {
      valid: false,
      messages: passwordErrors,
    };
  };
};

// Rule functions
const passwordsMustMatch = (p1: string, p2: string): RuleResult => {
  if (p1 !== p2) {
    return { passed: false, message: "Passwords do not match." };
  }
  return { passed: true, message: "" };
};

const atLeastOneUppercaseLetter = (p1: string, p2: string): RuleResult => {
  if (p1.search(/\\*([A-Z]+)\\*/) < 0) {
    return {
      passed: false,
      message: "Password must contain at least one UPPERCASE letter.",
    };
  }
  return { passed: true, message: "" };
};

const atLeastOneLowercaseLetter = (p1: string, p2: string): RuleResult => {
  if (p1.search(/\\*([a-z]+)\\*/) < 0) {
    return {
      passed: false,
      message: "Password must contain at least one lowercase letter.",
    };
  }
  return { passed: true, message: "" };
};

const between10and100Characters = (p1: string, p2: string): RuleResult => {
  if (p1.length < 10) {
    return {
      passed: false,
      message: "Password must be at least 10 characters long.",
    };
  } else if (p1.length > 100) {
    return {
      passed: false,
      message: "Password cannot exceed 100 characters.",
    };
  }
  return { passed: true, message: "" };
};

// default password validation function
const defaultPasswordValidator = createPasswordValidator([
  passwordsMustMatch,
  atLeastOneUppercaseLetter,
  atLeastOneLowercaseLetter,
  between10and100Characters,
]);

export default defaultPasswordValidator;
