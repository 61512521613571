import Plot from "react-plotly.js";
// import BSTextInput from "../forms/BSTextInput";
import styles from "./VolatilityAnalysis.module.css";
import useMounted from "../../hooks/useMounted";
// import { partial } from "lodash-es";
import { selectAuth } from "../auth/authSlice";
import {
  selectVolatilityAnalysis,
  // setVolatilityAnalysisData,
  VolatilityAnalysisData,
  Status,
  FuturesSelections,
  OptionsSelections,
  changeSelectedFutures,
  changeSelectedOptions,
  toggleSelectAllFutures,
  toggleSelectAllOptions,
} from "./volatilityAnalysisSlice";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { useNavigate, Link } from "react-router-dom";
import {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  useRef,
  SetStateAction,
} from "react";
import { requestSymbol } from "./volatilityAnalysisActions";
import { Height } from "@mui/icons-material";
import { PlotRelayoutEvent } from "plotly.js";
import { Layout } from "plotly.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// class FlatPrice {
//   #dates: Date[];
//   #values: number[];

//   constructor(dates: string[] | Date[], values: number[]) {
//     this.dates = dates;
//     this.values = values;
//   }
// }

export function VolatilityAnalysis(props: {}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // "inputs" - what we watch that determines whether we update
  const { email, isLoggedIn, loginMessage } = useAppSelector(selectAuth);
  const { status } = useAppSelector(selectVolatilityAnalysis);
  const volatilityAnalysisData: VolatilityAnalysisData = useAppSelector(
    (state) => state.volatilityAnalysis.data,
  );
  const selectedFutures: FuturesSelections = useAppSelector(
    (state) => state.volatilityAnalysis.selectedFutures,
  );
  const selectedOptions: OptionsSelections = useAppSelector(
    (state) => state.volatilityAnalysis.selectedOptions,
  );

  const symbolRef = useRef<HTMLInputElement>(null);

  const [currentDate, setCurrentDate] = useState("");
  const [formSymbol, setFormSymbol] = useState("");
  const [symbolTouched, setSymbolTouched] = useState(false);
  const [symbolError, setSymbolError] = useState("");
  const [formSlider, setFormSlider] = useState<number>(0);
  const [generalError, setGeneralError] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(1);
  const [timerIntervalId, setTimerIntervalId] = useState<
    string | number | NodeJS.Timeout | undefined
  >(undefined);
  const [animationStep, setAnimationStep] = useState(0);
  const [nextAnimationStep, setNextAnimationStep] = useState(0);

  const [layout, setLayout] = useState<Partial<Layout>>({});

  const isMounted = useMounted();

  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().setDate(new Date().getDate() - 10)),
  );
  const [endDate, setEndDate] = useState<Date>(new Date());

  const resetState = () => {
    console.debug("resetState...");

    setCurrentDate("");
    setFormSymbol("");
    setSymbolTouched(false);
    setSymbolError("");
    setFormSlider(0);
    setGeneralError("");
    setSubmitDisabled(false);
    setIsLoading(false);
    setIsAnimating(false);
    setAnimationSpeed(1);
    setTimerIntervalId(undefined);
    setAnimationStep(0);
    setNextAnimationStep(0);
  };

  // Reset on navigate
  useEffect(() => {
    console.debug("resetting state...");
    resetState();
  }, [navigate]);

  // focus symbol field when component mounts
  useEffect(() => {
    console.debug("focusing symbol...");
    symbolRef.current?.focus();
  }, []); // <-- no dependecies so this only runs once after mount

  useEffect(() => {
    console.debug(`updated volatilityAnalysisData:`);
    console.debug(volatilityAnalysisData);
    const earliestDate = Object.keys(volatilityAnalysisData)[0];
    setCurrentDate(earliestDate);

    // console.debug("useEffect 2");
    // TODO: update this effect hook to handle all futures selections
    // (make need a sort-selection handler that depends on metadata)
  }, [volatilityAnalysisData]);

  useEffect(() => {
    console.debug("updating status...");
    console.debug(`status: ${status}`);
    switch (status) {
      case Status.IDLE:
        setSubmitDisabled(false);
        setIsLoading(false);
        break;
      case Status.LOADING:
        setSubmitDisabled(true);
        setIsLoading(true);
        break;
      default:
        setSubmitDisabled(false);
        setIsLoading(false);
    }
  }, [status, dispatch]);

  useEffect(() => {
    // clear previous timer, if it exists
    try {
      console.debug("destroying timer...");
      clearInterval(timerIntervalId);
    } catch {
      console.debug("Unable to destroy timer");
    }
    if (isAnimating) {
      if (animationStep >= nextAnimationStep) {
        setTimerIntervalId(setInterval(animate, 50 * animationSpeed));
      }
      setNextAnimationStep(animationStep + 1);
    }
  }, [animationSpeed, isAnimating, animationStep]);

  const animate = () => {
    console.debug("animating...");
    console.debug(`${Object.keys(volatilityAnalysisData).length - 1}`);
    console.debug(`${formSlider}`);
    if (formSlider < Object.keys(volatilityAnalysisData).length - 1) {
      setFormSlider(formSlider + 1);
      setCurrentDate(Object.keys(volatilityAnalysisData)[formSlider + 1]);
      setAnimationStep(animationStep + 1);
    }
  };
  // disable submit button unless symbol is valid(?)
  // useEffect(() => {
  // setSubmitDisabled(!(emailValid && passwordValid));
  // }, [emailValid, passwordValid]);

  const sortByExpirationDateString = (a: string | null, b: string | null) => {
    //mirrors sort in fut list

    const dateA = a ? new Date(a) : null;
    const dateB = b ? new Date(b) : null;

    // Handle sorting logic with nulls
    if (dateA === null && dateB === null) return 0;
    if (dateA === null) return 1;
    if (dateB === null) return -1;

    return dateA.getTime() - dateB.getTime();
  };

  const handleFutCheckboxChange = (key: string) => {
    dispatch(changeSelectedFutures(key));
  };

  const handleOptCheckboxChange = (key: string) => {
    dispatch(changeSelectedOptions(key));
  };

  const handleFutSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleSelectAllFutures());
  };

  const handleOptSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleSelectAllOptions());
  };

  const createOnChange = (_fn: (s: string) => void) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // Update credentials in state
      _fn(e.target.value);
    };
    return onChange;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    // setSubmitDisabled(true);
    // setIsLoading(true);
    setGeneralError("");
    /*
    {
      '2024-05-20':
        'ZCN24': {
          'open':
          'high':
          'low':
          'close':
          'contractDate':
          'ivol': {
            'ZCM24': {
              'put': {
                '320': 1.40...,
                ...
              }
              'call': {
                '320': 1.4042...,
                ...
              }
            }
          }

        }
    }
    */

    dispatch(
      requestSymbol({
        symbol: formSymbol,
        startDate: startDate,
        endDate: endDate,
      }),
    );

    // setGeneralError(dataResult.user_message ? dataResult.user_message : "");

    // } else {
    // setErrorMessages("Please check your credentials!")
    // }
  };

  const updateSlider = (e: ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    setFormSlider(Number(e.target.value));
    setCurrentDate(Object.keys(volatilityAnalysisData)[Number(e.target.value)]);
  };

  const toggleAnimation = (e: ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    if (isAnimating) {
      // setAnimationStep(0);
      // setNextAnimationStep(0);
    }
    setIsAnimating(!isAnimating);
  };

  const symbolSort = (a: string, b: string): number => {
    if (a.slice(-2) < b.slice(-2)) {
      return -1;
    }
    if (a.slice(-2) > b.slice(-2)) {
      return 1;
    }
    if (a.slice(-2) === b.slice(-2)) {
      if (a.slice(-3) < b.slice(-3)) {
        return -1;
      }
      if (a.slice(-3) > b.slice(-3)) {
        return 1;
      }
      if (a.slice(-3) === b.slice(-3)) {
        return 0;
      }
    }
    return 999; // error? typescript will complain if we don't return a number
  };

  const handleRelayout = (newLayout: Partial<Layout>) => {
    setLayout({ ...layout, ...newLayout });
  };

  return (
    <div>
      <div className="w-100 bg-light">
        <div className="d-flex flex-column justify-content-between bg-light w-50 mx-2 my-2">
          <div className="d-flex flex-row align-items-end">
            <div className="d-flex flex-column">
              <label className="mb-2"> Symbol:</label>
              <input
                type="text"
                className="form-control w-auto"
                id="inputSymbol"
                ref={symbolRef}
                value={formSymbol}
                placeholder="/ES"
                onChange={createOnChange(setFormSymbol)}
                onBlur={() => setSymbolTouched(true)}
                onKeyDown={(e) => {
                  e.key == "Enter" && onSubmit(e);
                }}
              />
              <p className="text-danger validation-error">
                {symbolTouched && symbolError}
              </p>
            </div>
            <div className="d-flex flex-column">
              <label className="mb-2"> Start Date:</label>
              <DatePicker
                className="form-control w-auto"
                selected={startDate}
                onChange={(date: Date | null) => date && setStartDate(date)}
              />{" "}
            </div>
            <div className="d-flex flex-column">
              <label className="mb-2"> End Date:</label>
              <DatePicker
                className="form-control w-auto "
                selected={endDate}
                onChange={(date: Date | null) => date && setEndDate(date)}
              />{" "}
            </div>
            <div>
              <button
                className="btn btn-primary mb-2 mx-2"
                disabled={submitDisabled || isLoading}
                type="button"
                onClick={onSubmit}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Submit</>
                )}
              </button>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div>
              {generalError && generalError !== "" && (
                <div className="text-danger text-center mt-4">
                  {/* Error message */}
                  <p>{generalError}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 bg-light">
        <div>Current Date: {currentDate}</div>
        <div className="d-flex flex-row">
          <label htmlFor="dateRange" className="form-label">
            Select Date:
          </label>
          <div className="bg-dark">
            <input
              type="range"
              className="form-range"
              id="dateRange"
              name="dateRange"
              value={formSlider}
              min="0"
              max={
                Object.keys(volatilityAnalysisData).length
                  ? (Object.keys(volatilityAnalysisData).length - 1).toString()
                  : "3"
              }
              // max="20"
              step="1"
              onChange={updateSlider}
            />
          </div>
          <div>Slider value: {formSlider}</div>
        </div>
        {/*        <div>
          <button className="btn btn-primary" onClick={toggleAnimation}>
            {isAnimating ? "Pause" : "Play"}
          </button>
        </div>*/}
        {volatilityAnalysisData &&
          currentDate &&
          volatilityAnalysisData[currentDate] && (
            <>
              <Plot
                data={
                  [
                    ...Object.entries(selectedFutures)
                      .filter(([, meta]) => meta.checked)
                      .flatMap(([future]) => [
                        {
                          x: Object.keys(volatilityAnalysisData),

                          open: Object.keys(volatilityAnalysisData).map(
                            (k) =>
                              volatilityAnalysisData[k][future]?.open ?? null,
                          ),
                          high: Object.keys(volatilityAnalysisData).map(
                            (k) =>
                              volatilityAnalysisData[k][future]?.high ?? null,
                          ),
                          low: Object.keys(volatilityAnalysisData).map(
                            (k) =>
                              volatilityAnalysisData[k][future]?.low ?? null,
                          ),
                          close: Object.keys(volatilityAnalysisData).map(
                            (k) =>
                              volatilityAnalysisData[k][future]?.close ?? null,
                          ),

                          type: "ohlc",
                          name: future,
                        },

                        // { type: "bar", x: [1, 2, 3], y: [2, 5, 3] },
                      ]),
                    {
                      type: "scattergl",
                      // xref: "x",
                      // yref: "paper",
                      x: [currentDate, currentDate],
                      y: [
                        Math.min(
                          ...Object.keys(selectedFutures).flatMap((future) =>
                            Object.keys(volatilityAnalysisData).map(
                              (k) =>
                                volatilityAnalysisData[k][future]?.low ?? null,
                            ),
                          ),
                        ),
                        Math.max(
                          ...Object.keys(selectedFutures).flatMap((future) =>
                            Object.keys(volatilityAnalysisData).map(
                              (k) =>
                                volatilityAnalysisData[k][future]?.high ?? null,
                            ),
                          ),
                        ),
                      ],
                      // x0: currentDate,
                      // y0: 0,
                      // x1: currentDate,
                      // y1: 1,
                      line: { color: "blue", width: 4.0, dash: "solid" },
                      mode: "lines",
                      marker: { color: "blue" },
                      name: "Current Date",
                    },
                  ] as Object[]
                }
                // layout={{ width: 320, height: 240, title: "A Fancy Plot" }}
                layout={{
                  title: "Flat Price",
                  xaxis: { fixedrange: true, rangeslider: { visible: false } },
                  yaxis: {
                    range: [
                      (1 - 0.05) * //Assumes min fut is always positive, this is rarely untrue, use funct to simplify
                        Math.min(
                          ...Object.keys(volatilityAnalysisData).flatMap(
                            (date) =>
                              Object.keys(volatilityAnalysisData[date])
                                .filter((fut) => selectedFutures[fut].checked)
                                .map(
                                  (future) =>
                                    volatilityAnalysisData[date][future].low,
                                )
                                .filter(
                                  (low) =>
                                    low !== null && typeof low === "number", // Is this null check needed if  fut filter used prev
                                ),
                          ),
                        ),
                      (1 + 0.05) *
                        Math.max(
                          ...Object.keys(volatilityAnalysisData).flatMap(
                            (date) =>
                              Object.keys(volatilityAnalysisData[date])
                                .filter((fut) => selectedFutures[fut].checked)
                                .map(
                                  (future) =>
                                    volatilityAnalysisData[date][future].high,
                                ),
                          ),
                        ),
                    ],
                  },
                }}
              />

              <Plot
                data={[
                  {
                    x: Object.keys(volatilityAnalysisData[currentDate]).sort(
                      symbolSort,
                    ),
                    y: Object.keys(volatilityAnalysisData[currentDate])
                      .sort(symbolSort)
                      .map(
                        (k) => volatilityAnalysisData[currentDate][k]["close"],
                      ),
                    type: "scattergl",
                    mode: "lines+markers",
                    marker: { color: "blue" },
                  },

                  // { type: "bar", x: [1, 2, 3], y: [2, 5, 3] },
                ]}
                // layout={{ width: 320, height: 240, title: "A Fancy Plot" }}
                layout={{
                  title: "Forward Curve",
                  yaxis: {
                    range: [
                      -5 +
                        Math.min(
                          ...Object.keys(volatilityAnalysisData).flatMap(
                            (date) =>
                              Object.keys(volatilityAnalysisData[date])
                                .map(
                                  (future) =>
                                    volatilityAnalysisData[date][future].close,
                                )
                                .filter(
                                  (close) =>
                                    close !== null && typeof close === "number",
                                ),
                          ),
                        ),
                      5 +
                        Math.max(
                          ...Object.keys(volatilityAnalysisData).flatMap(
                            (date) =>
                              Object.keys(volatilityAnalysisData[date]).map(
                                (future) =>
                                  volatilityAnalysisData[date][future].close,
                              ),
                          ),
                        ),
                    ],
                  },
                }}
              />
              <Plot
                data={Object.entries(selectedOptions)
                  .filter(([, meta]) => meta.checked && meta.visible)
                  .map(([option, meta]) => {
                    if (
                      volatilityAnalysisData[currentDate][meta.underlying]
                        .options[option]
                    ) {
                      const futureClose =
                        volatilityAnalysisData[currentDate][meta.underlying][
                          "close"
                        ];

                      const ivolPuts = Object.entries(
                        volatilityAnalysisData[currentDate][meta.underlying][
                          "options"
                        ][option]["ivol"]["Puts"],
                      ).filter(([strike]) => Number(strike) < futureClose);
                      const ivolCalls = Object.entries(
                        volatilityAnalysisData[currentDate][meta.underlying][
                          "options"
                        ][option]["ivol"]["Calls"],
                      ).filter(([strike]) => Number(strike) >= futureClose);

                      const ivolSorted = [...ivolPuts, ...ivolCalls].sort(
                        ([strikeA], [strikeB]) =>
                          Number(strikeA) - Number(strikeB),
                      );

                      return {
                        x: ivolSorted.map(([strike]) => Number(strike)),
                        y: ivolSorted.map(([, ivol]) => ivol),
                        type: "scattergl",
                        mode: "lines+markers",
                        name: option,
                      };
                    } else {
                      return {};
                    }
                  })}
                // layout={{ width: 320, height: 240, title: "A Fancy Plot" }}
                layout={layout}
                onRelayout={(event) => handleRelayout(event)}
              />

              <div //Options Selection List
                className="card d-inline-block"
                style={{
                  width: "325px",
                  height: "450px",
                  marginLeft: "15px",
                  verticalAlign: "top",
                }}
              >
                <div
                  className="container h-100 p-2 d-flex flex-column"
                  style={{ overflowY: "auto", scrollbarWidth: "thin" }}
                >
                  <h6 className="text-center py-2">Available Options</h6>
                  <label
                    className={`form-check-label ${styles.noSelect} ms-1`}
                    style={{ fontWeight: "bold" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="all"
                      checked={
                        Object.values(selectedOptions).every(
                          (item) => !item.visible || item.checked,
                        ) //the item must be either checked or not currently visible
                      }
                      onChange={handleOptSelectAll}
                    />
                    {" Select All"}
                  </label>
                  <hr className="my-0" />

                  <div className="flex-grow-1" style={{ overflowY: "auto" }}>
                    {Object.entries(selectedOptions)
                      .filter(([, a]) => a.visible)
                      .sort(([, a], [, b]) =>
                        sortByExpirationDateString(
                          a.expirationDate,
                          b.expirationDate,
                        ),
                      )
                      .map(([key, meta]) => (
                        <div key={key}>
                          <label
                            className={`form-check-label ${styles.noSelect} ms-1`}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={key}
                              checked={meta.checked}
                              onChange={() => handleOptCheckboxChange(key)} //convert to dispatch command
                            />
                            {" " + key + " - " + meta.expirationDate}
                          </label>
                          <br />
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Futures Selection list */}
              <div
                className="card d-inline-block "
                style={{
                  width: "275px",
                  height: "450px",
                  marginLeft: "15px",
                  verticalAlign: "top",
                }}
              >
                <div
                  className="container h-100 p-2  d-flex flex-column"
                  style={{ overflowY: "auto" }}
                >
                  <h6 className="text-center py-2">Available Futures</h6>
                  <div>
                    <label
                      className={`form-check-label ${styles.noSelect} ms-1`}
                      style={{ fontWeight: "bold" }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="all"
                        checked={Object.values(selectedFutures).every(
                          (item) => item.checked,
                        )}
                        onChange={handleFutSelectAll}
                      />
                      {" Select All"}
                    </label>
                    <br />
                  </div>
                  <hr className="my-0" />

                  <div className="flex-grow-1" style={{ overflowY: "auto" }}>
                    {Object.entries(selectedFutures)
                      .sort(([, a], [, b]) =>
                        sortByExpirationDateString(
                          a.expirationDate,
                          b.expirationDate,
                        ),
                      )
                      .map(([key, meta]) => (
                        <div key={key}>
                          <label
                            className={`form-check-label ${styles.noSelect} ms-1`}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={key}
                              checked={meta.checked}
                              onChange={() => handleFutCheckboxChange(key)}
                            />
                            {" " +
                              key +
                              " ( " +
                              Object.values(selectedOptions).filter(
                                (item) => item.underlying === key,
                              ).length +
                              " ) - " +
                              meta.expirationDate}
                          </label>
                          <br />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
}

export default VolatilityAnalysis;
