import { createAsyncThunk } from "@reduxjs/toolkit";
import ax from "../../config/axios";
import { AxiosResponse, AxiosError } from "axios";

export type ResetRequestResponse = {
  status: string;
  // email?: string;
  error?: string;
  error_code?: number;
  status_code?: number;
  user_message: string;
  console_message_frontend?: string;
  // permissions?: string[];
  // is_authorized?: boolean;
  // is_email_confirmed?: boolean;
};

export type ResetFinalizeResponse = {
  status: string;
  // email?: string;
  error?: string;
  error_code?: number;
  status_code?: number;
  user_message: string;
  console_message_frontend?: string;
  // permissions?: string[];
  // is_authorized?: boolean;
  // is_email_confirmed?: boolean;
};

export type ErrorResponse = {
  user_message: string;
  console_message_frontend: string;
  status_code: number;
  status: string;
  error: string;
  error_code: number;
};

type ResetFinalizeParams = {
  email: string;
  rcode: string;
  new_password: string;
};
// Interfaces

const UNKNOWN_ERROR: ErrorResponse = {
  user_message:
    "Unknown error occurred. Please try again later or contact support",
  status: "Unknown",
  status_code: -1,
  error: "Unknown error occurred. Please try again later or contact support",
  error_code: -1,
  console_message_frontend: "",
};

// Functions
export const resetRequest = createAsyncThunk(
  "password-reset/reset-request",
  async (email: string, thunkAPI): Promise<ResetRequestResponse> => {
    try {
      const result = await ax.post("auth/request-password-reset", {
        email: email,
      });
      switch (result.status) {
        case 201:
          console.info(201);
          // result.data should be RegistrationResponse
          if (result.data.console_message_frontend) {
            console.info(result.data.console_message_frontend);
          }
          return result.data;
        default:
          console.debug("default");
          return result.data;
      }
    } catch (error: any) {
      console.debug("error");
      // console.log(error);
      switch (error.response?.status) {
        case 400:
          console.debug(400);
          throw thunkAPI.rejectWithValue(error.response.data as ErrorResponse);
        case 401:
          console.debug(401);
          throw thunkAPI.rejectWithValue(error.response.data as ErrorResponse);
        case 500:
          console.debug(500);
          throw thunkAPI.rejectWithValue(error.response.data as ErrorResponse);
        default:
          throw thunkAPI.rejectWithValue(UNKNOWN_ERROR);
      }
    } finally {
      // console.log("finally");
    }
  },
);

export const resetFinalize = createAsyncThunk(
  "password-reset/reset-finalize",
  async (
    params: ResetFinalizeParams,
    thunkAPI,
  ): Promise<ResetFinalizeResponse> => {
    try {
      const result = await ax.post("auth/finalize-password-reset", {
        email: params.email,
        rcode: params.rcode,
        new_password: params.new_password,
      });
      switch (result.status) {
        case 200:
          console.info(200);
          // result.data should be ResetFinalizeResponse
          if (result.data.console_message_frontend) {
            console.info(result.data.console_message_frontend);
          }
          return result.data;
        default:
          console.debug("default");
          return result.data;
      }
    } catch (error: any) {
      console.debug("error");
      console.log(error);
      switch (error.response?.status) {
        case 400:
          console.debug(400);
          throw thunkAPI.rejectWithValue(error.response.data as ErrorResponse);
        case 401:
          console.debug(401);
          throw thunkAPI.rejectWithValue(error.response.data as ErrorResponse);
        case 500:
          console.debug(500);
          throw thunkAPI.rejectWithValue(error.response.data as ErrorResponse);
        default:
          throw thunkAPI.rejectWithValue(UNKNOWN_ERROR);
      }
    } finally {
      // console.log("finally");
    }
  },
);
