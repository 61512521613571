const EMAIL_PATTERN = new RegExp(
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
);

export interface validationResponse {
  valid: boolean;
  message: string;
}

const createEmailValidator = (
  regex: RegExp,
  failure_message: string,
): ((email: string) => validationResponse) => {
  return (email: string): validationResponse => {
    if (regex.test(email)) {
      return { valid: true, message: "" };
    }
    return { valid: false, message: failure_message };
  };
};

const validateEmailDefault = createEmailValidator(
  EMAIL_PATTERN,
  "Please enter a valid email",
);

export default validateEmailDefault;
