import { ChangeEvent, forwardRef } from "react";

type BSTextInputProps = {
  label: string;
  required: boolean;
  value: string;
  type: string;
  id: string;
  name: string;
  touched: boolean;
  message: string;
  innerRef?: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
};

const BSTextInput = forwardRef<HTMLInputElement, BSTextInputProps>(
  function BSTextInput(props, ref) {
    const {
      label,
      required,
      value,
      type,
      id,
      name,
      touched,
      message,
      onChange,
      ...otherProps
    } = props;
    // const BSTextInput = forwardRef(function BSTextInput({}:BSTextInputProps, ref) {
    return (
      <div className="form-group text-left small">
        <label htmlFor="email" className="form-label">
          {label}
          {required && <span style={{ color: "red" }}> *</span>}
        </label>
        <input
          {...otherProps}
          ref={ref}
          className="form-control"
          value={value ? value : ""}
          onChange={onChange}
          type={type}
          name={name}
          id={id}
        />
        <p className="text-danger validation-error">{touched && message}</p>
      </div>
    );
  },
);

export default BSTextInput;
