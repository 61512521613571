import logo from "./logo.svg";
import Login from "./pages/Login";
import Counter from "./pages/Counter";
import Dashboard from "./pages/Dashboard";
import VolatilityAnalysis from "./pages/VolatilityAnalysis";
import Register from "./pages/Register";
import ManageAccount from "./pages/ManageAccount";
import PasswordReset from "./pages/PasswordResetRequest";
import PasswordResetFinalize from "./pages/PasswordResetFinalize";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import PrivateRoutes from "./components/auth/PrivateRoutes";
import ProtectedRoutes from "./components/auth/ProtectedRoutes";
import "./App.css";
import "./GlobalStyles.scss";

function App() {
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Navigate to="/login" />
            </div>
          }
        />
        <Route
          path="/login"
          element={
            <Login
              resetPasswordUrl="/reset-password"
              registerUrl="/register"
              // exitUrl="/loading-screen"
              exitUrl="/volatility-analysis"
            />
          }
        />
        <Route
          path="/register"
          element={<Register loginUrl="/login" exitUrl="/dashboard" />}
        />

        <Route
          path="/reset-password"
          element={
            <PasswordReset loginUrl="/login" exitUrl="/loading-screen" />
          }
        />
        <Route
          path="/reset-password-finalize"
          element={
            <PasswordResetFinalize
              loginUrl="/login"
              // exitUrl="/loading-screen"
              exitUrl="/dashboard"
            />
          }
        />

        <Route path="/counter" element={<Counter />} />
        {/*<Route path="/volatility-analysis" element={<VolatilityAnalysis />} />*/}
        <Route element={<PrivateRoutes />}>
          <Route path="/manage-account" element={<ManageAccount />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/volatility-analysis"
              element={<VolatilityAnalysis />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
