import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "./components/counter/counterSlice";
import authReducer from "./components/auth/authSlice";
import volatilityAnalysisReducer from "./components/volatility-analysis/volatilityAnalysisSlice";
import registerReducer from "./components/register/registerSlice";
import passwordResetReducer from "./components/password-reset/passwordResetSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    volatilityAnalysis: volatilityAnalysisReducer,
    register: registerReducer,
    passwordReset: passwordResetReducer,
  },
  devTools: import.meta.env.VITE_NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
