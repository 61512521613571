import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";

import React from "react";

function PrivateRoutes() {
  const { email, isLoggedIn } = useAppSelector((state) => state.auth);
  return email && isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoutes;
