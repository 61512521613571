import ax from "../../config/axios";
import { AxiosResponse, AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

type VolatilityAnalysisResponse = {
  status: string;
  error?: string;
  error_code?: number;
  status_code?: number;
  user_message?: string;
  console_message_frontend?: string;
};

const UNKNOWN_ERROR = {
  status: "Unknown",
  user_message:
    "Unknown error occurred. Please try again later or contact support.",
};

const CONNECTION_ERROR = {
  status: "Unknown",
  user_message:
    "Connection failed. The server may be unavailable or your internet connection may be down.",
};

export const requestSymbol = createAsyncThunk(
  "volatility-analysis/requestSymbol",
  async (
    arg: { symbol: string; startDate: Date; endDate: Date },
    thunkAPI,
  ): Promise<VolatilityAnalysisResponse> => {
    try {
      console.debug(`Here with ${arg.startDate} and ${arg.endDate}`);
      const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if necessary
        return `${year}${month}${day}`;
      };
      const result = await ax.post("data/volatility-analysis", {
        symbol: arg.symbol,
        startDate: formatDate(arg.startDate),
        endDate: formatDate(arg.endDate),
      });
      switch (result.status) {
        case 200:
          console.info(200);
          // result.data should be LoginResponse
          if (result.data.console_message_frontend) {
            console.info(result.data.console_message_frontend);
          }
          return result.data;
        default:
          console.debug("default");
          return result.data;
      }
    } catch (error: any) {
      console.debug("requestSymbol error:");
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        throw thunkAPI.rejectWithValue(CONNECTION_ERROR);
      }
      switch (error.response?.status) {
        case 400:
          console.debug(400);
          throw thunkAPI.rejectWithValue(error.response?.data);
        case 401:
          console.debug(401);
          throw thunkAPI.rejectWithValue(error.response?.data);
        default:
          throw thunkAPI.rejectWithValue(UNKNOWN_ERROR);
      }
    } finally {
      // console.log("finally");
    }
  },
);
