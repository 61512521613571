// import styles from "./AccountInfo.module.css";
// import useMounted from "../../hooks/useMounted";
// import { partial } from "lodash-es";
import { selectAuth, reset } from "../auth/authSlice";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
// import { useNavigate, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { useEffect } from "react";
// import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
// import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

export function AccountInfo() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { email, profile } = useAppSelector(selectAuth);

  // const isMounted = useMounted();

  // Reset on navigate
  useEffect(() => {
    // dispatch(reset());
  }, [navigate, dispatch]);

  const NOT_PROVIDED = "[Not Provided]";

  return (
    <div className="card w-100">
      <div className="card-body">
        <h4 className="card-title">User Profile:</h4>
        <div className="d-flex flex-column">
          <div>
            <strong>Email Address:</strong> {email ? email : NOT_PROVIDED}
          </div>
          <div>
            <strong>First Name:</strong>{" "}
            {profile.firstName ? profile.firstName : NOT_PROVIDED}
          </div>
          <div>
            <strong>Last Name:</strong>{" "}
            {profile.lastName ? profile.lastName : NOT_PROVIDED}
          </div>
          <div>
            <strong>Mobile Phone:</strong>{" "}
            {profile.mobilePhone ? profile.mobilePhone : NOT_PROVIDED}
          </div>
          <div>
            <strong>Created At:</strong>{" "}
            {profile.createdAt ? profile.createdAt : NOT_PROVIDED}
          </div>
        </div>
        <div>
          <button type="button" className="btn btn-danger">
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
}

export default AccountInfo;
