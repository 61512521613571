import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { register, ErrorResponse } from "./registerActions";
import { RootState, AppThunk } from "../../store";

const initialState = {
  isLoading: false,
  isError: false,
  message: "",
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = "";
        console.debug("state:");
        console.debug(state);
        console.debug("action:");
        console.debug(action);
        // state.profile.createdAt = action.payload.created_at;
        // state.data = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        console.debug("register.rejected action:");
        console.debug(action);
        state.isLoading = false;
        state.isError = true;
        state.message = (action.payload as ErrorResponse).user_message;
      });
  },
});

const { actions, reducer } = registerSlice;

// actions automatically generated by redux
export const { reset } = actions;

export const selectRegister = (state: RootState) => state.register;

export default reducer;
