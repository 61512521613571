import axios from "axios";
import { logout } from "../components/auth/authActions";
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const defaultInstance = axios.create({
  baseURL: import.meta.env.VITE_AXIOS_BASE_URL,
  timeout: Number(import.meta.env.VITE_AXIOS_TIMEOUT),
  // headers: { "X-Custom-Header": "foobar" },
  withCredentials: true,
});

// export function configureDefaultInstance(store) {
//   defaultInstance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       const status = error.status || error.response.status;
//       if (status === UNAUTHORIZED || status === FORBIDDEN) {
//         //Only dispatch logout if they are logged in else we will get a infinite loop
//         if (store.getState().auth.isLoggedIn) {
//           // store.dispatch(logout());  // log out the user if they try to access a resource for which they are unauthorized
//         }
//       }

//       return Promise.reject(error);
//     },
//   );
// }
export default defaultInstance;
