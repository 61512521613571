import { ChangeEvent } from "react";
import { FormEvent } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { partial } from "lodash-es";
import { useAppSelector, useAppDispatch } from "../hooks/redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { Counter } from "../components/counter/Counter";
import logo from "../logo.svg";

function CounterScreen() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
        <Link to="/login">Click to Login</Link>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span>
      </header>
    </div>
  );
}

export default CounterScreen;
