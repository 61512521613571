import React from "react";
import CheckEmailVerification from "../components/check-email-verification/CheckEmailVerification";
import AccountInfo from "../components/account-info/AccountInfo";
import Navbar from "../components/navbar/Navbar";
import NavbarTabs from "../components/navbar-tabs/NavbarTabs";

function ManageAccountScreen() {
  return (
    <div className="container-fluid bg-theme" style={{ height: "100vh" }}>
      <div className="row h-100">
        <div className="d-flex flex-column align-items-center justify-content-start">
          <div className="w-100 mb-2">
            <Navbar />
            {/*<NavbarTabs />*/}
          </div>
          <div className="w-100">
            <AccountInfo />
          </div>
          <div className="w-100 mt-2">
            <CheckEmailVerification exitUrl="/login" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageAccountScreen;
