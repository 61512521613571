// import BSTextInput from "../forms/BSTextInput";
// import logoUrl from "../../assets/csc_logo_transparent.png";
// import styles from "./CheckEmailVerification.module.css";
import useMounted from "../../hooks/useMounted";
// import { partial } from "lodash-es";
import { selectAuth, reset } from "../auth/authSlice";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, ChangeEvent, FormEvent } from "react";
// import validateEmail from "../validators/emailValidator";
import {
  resendEmailVerification,
  recheckEmailVerification,
} from "./checkEmailVerificationActions";
// import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
// import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

export function CheckEmailVerification(props: { exitUrl: string }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { email, isLoggedIn, authentication } = useAppSelector(selectAuth);

  const {
    isEmailVerified,
    isVerificationSent,
    isResendLoading,
    isRecheckLoading,
    hasCheckedEmailVerification,
    isError,
    message,
  } = useAppSelector((state) => state.auth.emailVerification);

  const [generalError, setGeneralError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const isMounted = useMounted();

  // Reset on navigate
  useEffect(() => {
    // dispatch(reset());
  }, [navigate]);

  useEffect(() => {
    if (isRecheckLoading) {
      setSuccessMessage("");
      setGeneralError("");
    }
    if (isMounted && hasCheckedEmailVerification && !isRecheckLoading) {
      if (isEmailVerified) {
        // navigate(props.exitUrl);
        setSuccessMessage("Email successfully verified!");
      } else {
        setSuccessMessage("");
        setGeneralError(
          "Email is still unverified. Please verify email and check again.",
        );
      }
    }
  }, [isEmailVerified, isRecheckLoading, hasCheckedEmailVerification]);

  useEffect(() => {
    if (isVerificationSent) {
      setSuccessMessage(
        "Verification email resent successfully. Please check your email " +
          "and follow the instructions to verify your account.",
      );
      setGeneralError("");
    }
  }, [isVerificationSent]);

  useEffect(() => {
    if (isError) {
      setSuccessMessage("");
      setGeneralError(message);
    }
  }, [isError]);

  const handleResendEmailClick = async () => {
    setGeneralError("");
    setSuccessMessage("");
    dispatch(resendEmailVerification());
  };

  const handleRecheckEmailClick = async () => {
    setGeneralError("");
    setSuccessMessage("");
    dispatch(recheckEmailVerification());
  };

  return (
    <div>
      {!isEmailVerified && isMounted && (
        <div className="alert alert-warning">
          Your email address has not yet been verified. Please verify your email
          address to access the portal. <br />
          <button
            type="button"
            className="btn btn-warning"
            disabled={isResendLoading || isRecheckLoading || isVerificationSent}
            onClick={() => handleResendEmailClick()}
          >
            {isResendLoading && (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            )}
            <>Resend Verification Email</>
          </button>{" "}
          <button
            type="button"
            className="btn btn-success"
            disabled={isResendLoading || isRecheckLoading}
            onClick={() => handleRecheckEmailClick()}
          >
            {isRecheckLoading && (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            )}
            Recheck Email Verification
          </button>
          <div>
            {generalError && generalError !== "" && (
              <div className="text-danger mt-4">
                <p>{generalError}</p>
              </div>
            )}
          </div>
          <div>
            {successMessage && successMessage !== "" && (
              <div className="text-success mt-4">
                <p>{successMessage}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckEmailVerification;
