import { createAsyncThunk } from "@reduxjs/toolkit";
import ax from "../../config/axios";
import { AxiosResponse, AxiosError } from "axios";

type ResendEmailVerificationResponse = {
  status: string;
  email?: string;
  error?: string;
  error_code?: number;
  status_code?: number;
  user_message?: string;
  console_message_frontend?: string;
  permissions?: string[];
  is_authorized?: boolean;
  is_email_confirmed?: boolean;
  verified: boolean;
};

type ErrorResponse = {
  console_message_frontend?: string;
  error?: string;
  error_code?: number;
  status: string;
  status_code: number;
  user_message: string;
};

const UNKNOWN_ERROR = {
  status: "Unknown",
  user_message:
    "Unknown error occurred. Please try again later or contact support",
};

const CONNECTION_ERROR = {
  status: "Unknown",
  user_message:
    "Connection failed. The server may be unavailable or your internet connection may be down.",
};

export const resendEmailVerification = createAsyncThunk(
  "check-email-verification/resend-email-verification",
  async (_: void, thunkAPI): Promise<ResendEmailVerificationResponse> => {
    try {
      const result = await ax.post("auth/resend-email-verification");
      switch (result.status) {
        case 200:
          console.info(200);
          // result.data should be ResendEmailVerificationResponse
          if (result.data.console_message_frontend) {
            console.info(result.data.console_message_frontend);
          }
          return result.data;
        default:
          console.debug("default");
          return result.data;
      }
    } catch (error: any) {
      console.debug("resendEmailVerification error");
      console.log(error);
      switch (error.response?.status) {
        case 400:
          console.debug(400);
          throw thunkAPI.rejectWithValue(error.response?.data);
        case 401:
          console.debug(401);
          throw thunkAPI.rejectWithValue(error.response?.data);
        default:
          throw thunkAPI.rejectWithValue(UNKNOWN_ERROR);
      }
    } finally {
      // console.log("finally");
    }
  },
);

export const recheckEmailVerification = createAsyncThunk(
  "check-email-verification/recheck-email-verification",
  async (_: void, thunkAPI): Promise<ResendEmailVerificationResponse> => {
    try {
      const result = await ax.post("auth/recheck-email-verification");
      switch (result.status) {
        case 200:
          console.info(200);
          // result.data should be ResendEmailVerificationResponse
          if (result.data.console_message_frontend) {
            console.info(result.data.console_message_frontend);
          }
          return result.data;
        default:
          console.debug("default");
          return result.data;
      }
    } catch (error: any) {
      console.debug("recheckEmailVerification error");
      console.log(error);
      switch (error.response?.status) {
        case 400:
          console.debug(400);
          throw thunkAPI.rejectWithValue(error.response?.data);
        case 401:
          console.debug(401);
          throw thunkAPI.rejectWithValue(error.response?.data);
        default:
          throw thunkAPI.rejectWithValue(UNKNOWN_ERROR);
      }
    } finally {
      // console.log("finally");
    }
  },
);
