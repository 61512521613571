import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import ax from "../../config/axios";

type RegistrationResponse = {
  status: string;
  // email?: string;
  error?: string;
  error_code?: number;
  status_code?: number;
  user_message?: string;
  console_message_frontend?: string;
  // permissions?: string[];
  // is_authorized?: boolean;
  // is_email_confirmed?: boolean;
};

// Interfaces
export interface RegisterCredentials {
  email: string;
  password: string;
}

export interface ErrorWithResponse extends Error {
  response?: {
    data?: {
      code?: string;
    };
  };
}

export type ErrorResponse = {
  console_message_frontend?: string;
  error?: string;
  error_code?: number;
  status: string;
  status_code: number;
  user_message: string;
};

const UNKNOWN_ERROR = {
  status: "Unknown",
  user_message:
    "Unknown error occurred. Please try again later or contact support",
};
//
export const register = createAsyncThunk(
  "register/register",
  async (
    args: {
      userCredentials: RegisterCredentials;
      successCallback: Function;
    },
    thunkAPI,
  ): Promise<RegistrationResponse> => {
    try {
      let callback: Function = args.successCallback;
      if (typeof args.successCallback === "undefined") {
        callback = (): void => {};
      }
      const result = await ax.post("auth/register-user", {
        email: args.userCredentials.email,
        password: args.userCredentials.password,
        first_name: "",
        last_name: "",
        mobile_phone: "",
      });
      switch (result.status) {
        case 201:
          console.info(201);
          // result.data should be RegistrationResponse
          if (result.data.console_message_frontend) {
            console.info(result.data.console_message_frontend);
          }
          callback();
          return result.data;
        default:
          console.debug("default");
          callback();
          return result.data;
      }
    } catch (error: any) {
      console.debug("register error:");
      console.debug(error);
      switch (error.response?.status) {
        case 400:
          console.debug(400);
          throw thunkAPI.rejectWithValue(error.response?.data);
        case 401:
          console.debug(401);
          throw thunkAPI.rejectWithValue(error.response?.data);
        case 500:
          console.debug(500);
          throw thunkAPI.rejectWithValue(error.response?.data);
        default:
          throw thunkAPI.rejectWithValue(UNKNOWN_ERROR);
      }
    } finally {
      // console.log("finally");
    }
  },
);
