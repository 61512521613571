import BSTextInput from "../forms/BSTextInput";
import logoUrl from "../../assets/csc_logo_transparent.png";
import styles from "./Register.module.css";
import useMounted from "../../hooks/useMounted";
// import { partial } from "lodash-es";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect, ChangeEvent, FormEvent, useRef } from "react";
import validateEmail from "../validators/emailValidator";
import validatePasswords from "../validators/passwordRegistrationValidator";
import { selectAuth, reset } from "../auth/authSlice";
import { login } from "../auth/authActions";
import { register } from "./registerActions";
import { selectRegister } from "./registerSlice";

export function Register(props: { loginUrl: string; exitUrl: string }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { email, isLoggedIn, authentication } = useAppSelector(selectAuth);
  const { isLoading, isError, message } = useAppSelector(selectRegister);

  const emailRef = useRef<HTMLInputElement>(null);
  const loginRef = useRef<HTMLAnchorElement>(null);

  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formConfirmPassword, setFormConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState([""]);
  const [generalError, setGeneralError] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const isMounted = useMounted();

  // Reset on navigate
  useEffect(() => {
    dispatch(reset());
    setFormEmail("");
    setFormPassword("");
    setFormConfirmPassword("");
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordErrors([""]);
    setGeneralError("");
    setEmailValid(false);
    setPasswordValid(false);
    setEmailTouched(false);
    setPasswordTouched(false);
    setConfirmPasswordTouched(false);
    setSubmitDisabled(false);
    // setIsLoading(false);
  }, [navigate]);

  useEffect(() => {
    if (isError) {
      setGeneralError(message);
    }
  }, [message, isError]);

  // redirect to exit screen
  useEffect(() => {
    if (isLoggedIn && email) {
      // If success or email in state then redirect to exit url
      navigate(props.exitUrl);
    }
  }, [email, isLoggedIn, navigate]);

  // validate form
  useEffect(() => {
    setSubmitDisabled(!(emailValid && passwordValid));
  }, [emailValid, passwordValid]);

  // Validate email
  useEffect(() => {
    if (isMounted) {
      const email_validation = validateEmail(formEmail);
      if (email_validation.valid) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
      setEmailError(email_validation.message);
    }
    // we are not adding isMounted to the dependency array as it would result in error message being shown early
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formEmail]);

  // Validate passwords
  useEffect(() => {
    if (isMounted) {
      const validationResult = validatePasswords(
        formPassword,
        formConfirmPassword,
      );

      setPasswordValid(validationResult.valid);
      setConfirmPasswordErrors(
        validationResult.messages ? validationResult.messages : [],
      );
    }
  }, [formPassword, formConfirmPassword, isMounted]);

  const createOnChange = (fn: Function) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // Update credentials in state
      fn(e.target.value);
    };
    return onChange;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // re-check email/password validation

    // display message to user if invalid; set focus to invalid item

    // submit to backend if valid

    const emailValidationResult = validateEmail(formEmail);
    if (emailValidationResult.valid) {
      setSubmitDisabled(true);
      // setIsLoading(true);
      setGeneralError("");

      dispatch(
        register({
          userCredentials: { email: formEmail, password: formPassword },
          successCallback: () => {
            dispatch(login({ email: formEmail, password: formPassword }));
            emailRef.current?.focus();
            loginRef.current?.focus();
            // setGeneralError(message ? message : "");
            setSubmitDisabled(false);
          },
        }),
      );
    } else {
      // setErrorMessages("Please check your credentials!")
      setEmailError(emailValidationResult.message);
    }
  };

  return (
    <div
      className={"card mx-auto auth-card w-100 " + styles.loginContainer}
      // style={{ minWidth: "300px" }}
    >
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={logoUrl}
            alt="AG Logo"
            className={"mt-2 mb-4 mx-auto " + styles.companyLogo}
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">
            Register a New
            <br />
            Trader Portal Account
          </h5>
        </div>
        <div className="mt-2 text-left">
          <form onSubmit={onSubmit}>
            <BSTextInput
              ref={emailRef}
              label={"Email Address"}
              type={"email"}
              // onChange={createOnChange("formEmail")}
              onChange={createOnChange(setFormEmail)}
              name={"email"}
              value={formEmail}
              touched={emailTouched}
              message={emailError}
              required={false}
              placeholder={"someone@example.com"}
              id={"email"}
              onBlur={() => setEmailTouched(true)}
            />
            <div className="form-group text-left small ms-4 mt-4 w-75">
              <div>
                <p className="info-text">Minimum Password Requirements:</p>
                <ul className="info-list">
                  <li>At least 10 characters</li>
                  <li>Contains both UPPERCASE and lowercase letters</li>
                </ul>
              </div>
            </div>
            <BSTextInput
              label={"Password"}
              type={"password"}
              // onChange={createOnChange("formPassword")}
              onChange={createOnChange(setFormPassword)}
              name={"password"}
              value={formPassword}
              touched={passwordTouched}
              message={""}
              required={false}
              id={"password"}
              placeholder={"password"}
              autoComplete="off"
              onBlur={() => setPasswordTouched(true)}
            />

            <BSTextInput
              label={"Confirm Password"}
              type={"password"}
              // onChange={createOnChange("formConfirmPassword")}
              onChange={createOnChange(setFormConfirmPassword)}
              name={"confirmPassword"}
              value={formConfirmPassword}
              touched={confirmPasswordTouched}
              message={""}
              required={false}
              id={"confirmPassword"}
              placeholder={"password"}
              autoComplete="off"
              onBlur={() => setConfirmPasswordTouched(true)}
            />
            <ul className="error-list">
              {!passwordValid &&
                passwordTouched &&
                confirmPasswordTouched &&
                confirmPasswordErrors.map((error, index) => (
                  <li key={index} className="text-danger">
                    {error}
                  </li>
                ))}
            </ul>

            <div className="text-center">
              <button
                className="btn btn-primary w-75 mt-4"
                disabled={submitDisabled || isLoading}
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Continue</>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="text-center mt-4">
          {/* Error message */}
          <p className="text-danger">{generalError}</p>
        </div>
        <div className="mt-4 w-100 text-center">
          <small>
            <Link
              style={{ textDecoration: "none" }}
              to={props.loginUrl}
              ref={loginRef}
            >
              &lt;&lt; Return to Login
            </Link>{" "}
          </small>
        </div>
      </div>
    </div>
  );
}

export default Register;
