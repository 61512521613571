import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../store";

import { requestSymbol } from "./volatilityAnalysisActions";
import VolatilityAnalysis from "./VolatilityAnalysis";
import { constant } from "lodash-es";

// export interface AuthState {
//   authentication: object;
// }

// const initialState: AuthState = {

const sortByExpirationDateString = (a: string | null, b: string | null) => {
  //mirrors sort in fut list

  const dateA = a ? new Date(a) : null;
  const dateB = b ? new Date(b) : null;

  // Handle sorting logic with nulls
  if (dateA === null && dateB === null) return 0;
  if (dateA === null) return 1;
  if (dateB === null) return -1;

  return dateA.getTime() - dateB.getTime();
};

const initalizeSelections = (newData: VolatilityAnalysisData) => {
  const selections: selectionState = {};

  const newSelections: Selections = {
    selectedFutures: {},
    selectedOptions: {},
  };

  for (const date in newData) {
    for (const fut in newData[date]) {
      if (!newSelections.selectedFutures[fut]) {
        newSelections.selectedFutures[fut] = {
          checked: false,
          expirationDate: newData[date][fut].futuresExpirationDate,
        };
      }
      Object.keys(newData[date][fut].options).forEach((option) => {
        if (!newSelections.selectedOptions[option]) {
          newSelections.selectedOptions[option] = {
            checked: false,
            visible: false,
            expirationDate:
              newData[date][fut].options[option].meta.expirationDate,
            underlying: fut,
          };
        }
      });
    }
  }
  //selection state is built, next add inital selections

  const earliestFut = Object.entries(newSelections.selectedFutures).sort(
    ([, a], [, b]) =>
      sortByExpirationDateString(a.expirationDate, b.expirationDate),
  )[0][0];
  console.log(earliestFut);
  newSelections.selectedFutures[earliestFut].checked = true;
  Object.keys(newSelections.selectedOptions).map(
    (opt) =>
      newSelections.selectedOptions[opt].underlying === earliestFut &&
      (newSelections.selectedOptions[opt].visible = true),
  );

  Object.entries(newSelections.selectedOptions)
    .filter(([, meta]) => meta.visible)
    .sort(([, a], [, b]) =>
      sortByExpirationDateString(a.expirationDate, b.expirationDate),
    )
    .slice(0, 3)
    .forEach(([a]) => (newSelections.selectedOptions[a].checked = true));

  return newSelections;
};

export const enum Status {
  LOADING = "LOADING",
  IDLE = "IDLE",
}

export interface VolatilityAnalysisData {
  [index: string]: {
    [index: string]: {
      open: number;
      high: number;
      low: number;
      close: number;
      futuresExpirationDate: string;
      options: {
        [index: string]: {
          meta: { expirationDate: string };
          ivol: {
            Calls: { [index: string]: number };
            Puts: { [index: string]: number };
          };
        };
      };
    };
  };
}

export interface selectionState {
  [future: string]: {
    checked: boolean;
    expirationDate: string;
    options: {
      [option_contract: string]: { checked: boolean; expirationDate: string };
    };
  };
}
interface Selections {
  selectedFutures: FuturesSelections;
  selectedOptions: OptionsSelections;
}

export interface FuturesSelections {
  [future: string]: {
    checked: boolean;
    expirationDate: string;
  };
}

export interface OptionsSelections {
  [option: string]: {
    visible: boolean;
    checked: boolean;
    expirationDate: string;
    underlying: string;
  };
}

// interface VolatilityAnalysisState{
//   data : VolatilityAnalysisData;
//   status: Status;
//   selectedFutures: FuturesSelections;
//   selectedOptions: OptionsSelections;
// }

const initialState = {
  data: {},
  status: Status.IDLE,
  selectedFutures: {},
  selectedOptions: {},
};

export const volatilityAnalysisSlice = createSlice({
  name: "volatilityAnalysis",
  initialState,
  reducers: {
    reset: (state) => initialState,
    // setVolatilityAnalysisData: (state, action) => {
    //   console.debug("state:");
    //   console.debug(state);
    //   console.debug("action:");
    //   console.debug(action);
    //   // state.profile.createdAt = action.payload.created_at;
    //   state.data = action.payload;
    // },
    changeSelectedFutures: (state, action: PayloadAction<string>) => {
      const fut_key = action.payload;
      const selectedFutures: FuturesSelections = state.selectedFutures;
      const selectedOptions: OptionsSelections = state.selectedOptions;

      if (selectedFutures[fut_key]) {
        selectedFutures[fut_key].checked = !selectedFutures[fut_key].checked;

        Object.entries(selectedOptions)
          .filter(([, meta]) => meta.underlying === fut_key)
          .map(([, meta]) => (meta.visible = !meta.visible));
      } else {
        console.warn(`The key '${fut_key}' does not exist in selectedFutures.`);
      }
    },
    changeSelectedOptions: (state, action: PayloadAction<string>) => {
      const opt_key = action.payload;
      const selectedOptions: OptionsSelections = state.selectedOptions;

      if (selectedOptions[opt_key]) {
        selectedOptions[opt_key].checked = !selectedOptions[opt_key].checked;
      } else {
        console.warn(`The key '${opt_key}' does not exist in selectedOptions.`);
      }
    },
    toggleSelectAllFutures: (state) => {
      const selectedFutures: FuturesSelections = state.selectedFutures;
      const selectedOptions: OptionsSelections = state.selectedOptions;

      const allChecked = Object.values(selectedFutures).every(
        (item) => item.checked,
      );

      Object.values(selectedFutures).forEach((item) => {
        item.checked = !allChecked;
      });

      Object.values(selectedOptions).forEach((item) => {
        item.visible = !allChecked;
      });
    },
    toggleSelectAllOptions: (state) => {
      const selectedOptions: OptionsSelections = state.selectedOptions;

      const allChecked = Object.values(selectedOptions).every(
        (item) => !item.visible || item.checked,
      );

      Object.values(selectedOptions).forEach((item) => {
        if (item.visible) {
          item.checked = !allChecked;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestSymbol.pending, (state, action) => {
        state.status = Status.LOADING;
      })
      .addCase(requestSymbol.fulfilled, (state, action) => {
        console.debug("state:");
        console.debug(state);
        console.debug("action:");
        console.debug(action);
        state.data = action.payload;
        state.status = Status.IDLE;
        const selections = initalizeSelections(state.data);
        state.selectedOptions = selections.selectedOptions;
        state.selectedFutures = selections.selectedFutures;
      })
      .addCase(requestSymbol.rejected, (state, action) => {
        state.status = Status.IDLE;
        console.debug("requestSymbol.rejected action:");
        console.debug(action);
        state.status = Status.IDLE;
      });
  },
});

const { actions, reducer } = volatilityAnalysisSlice;

// actions automatically generated by redux
export const {
  reset,
  changeSelectedFutures,
  changeSelectedOptions,
  toggleSelectAllOptions,
  toggleSelectAllFutures,
} = actions;

export const selectVolatilityAnalysis = (state: RootState) =>
  state.volatilityAnalysis;

export default reducer;




// interface futuresState {
//   [index: string]: {
//     isChecked: boolean;
//     isVisible: boolean;
//   };
// }

// const initialFutState: futuresState = {};

// const futuresStateSlice = createSlice({
//   name: "futures",
//   initialState: initialFutState,
//   reducers: {
//     // Add a new future with a given key
//     addFuture: (
//       state,
//       action: PayloadAction<{
//         key: string;
//         isChecked: boolean;
//         isVisible: boolean;
//       }>,
//     ) => {
//       const { key, isChecked, isVisible } = action.payload;
//       if (!state[key]) {
//         state[key] = { isChecked, isVisible };
//       }
//     },

//     // Toggle the isChecked property of a specific future (by key)
//     toggleChecked: (state, action: PayloadAction<string>) => {
//       const future = state[action.payload];
//       if (future) {
//         future.isChecked = !future.isChecked;
//       }
//     },

//     // Toggle the isVisible property of a specific future (by key)
//     toggleVisibility: (state, action: PayloadAction<string>) => {
//       const future = state[action.payload];
//       if (future) {
//         future.isVisible = !future.isVisible;
//       }
//     },

//     // Remove a future by key
//     removeFuture: (state, action: PayloadAction<string>) => {
//       const key = action.payload;
//       if (state[key]) {
//         delete state[key];
//       }
//     },

//     // Update both isChecked and isVisible for a specific future by key
//     updateFuture: (
//       state,
//       action: PayloadAction<{
//         key: string;
//         isChecked: boolean;
//         isVisible: boolean;
//       }>,
//     ) => {
//       const { key, isChecked, isVisible } = action.payload;
//       if (state[key]) {
//         state[key].isChecked = isChecked;
//         state[key].isVisible = isVisible;
//       }
//     },
//   },
// });