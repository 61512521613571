import React from "react";
import Register from "../components/register/Register";

function RegisterScreen(props: { loginUrl: string; exitUrl: string }) {
  return (
    <div className="container-fluid bg-theme" style={{ height: "100vh" }}>
      <div className="row h-100">
        <div className="d-flex align-items-center justify-content-center">
          <div className="col-md-4 left-debug">&nbsp;</div>
          <div className="col-md-4">
            <Register loginUrl={props.loginUrl} exitUrl={props.exitUrl} />
          </div>
          <div className="col-md-4 right-debug">&nbsp;</div>
        </div>
      </div>
    </div>
  );
}

export default RegisterScreen;
