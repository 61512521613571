// import BSTextInput from "../forms/BSTextInput";
import logoUrl from "../../assets/csc_logo_transparent.png";
import styles from "./Navbar.module.css";
// import useMounted from "../../hooks/useMounted";
// import { partial } from "lodash-es";
import { selectAuth } from "../auth/authSlice";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { logout } from "../auth/authActions";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import BSNavLink from "./BSNavLink";

export function Navbar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { email, isLoggedIn, authentication, scopes } =
    useAppSelector(selectAuth);

  // const isMounted = useMounted();

  const location = useLocation();

  // Reset on navigate
  // useEffect(() => {
  //   // dispatch(reset());
  //   console.debug("useEffect [navigate, dispatch]");
  // }, [navigate, dispatch]);

  // handle authentication state
  // useEffect(() => {
  //   console.debug("in useEffect(authentication.isLoading)");
  // }, [authentication.isLoading]);

  useEffect(() => {
    if (!isLoggedIn || !email) {
      console.debug("Navigating to /login...");
      navigate("/login");
    }
  }, [email, isLoggedIn]);

  const handleLogoutClick = async () => {
    dispatch(
      logout(() => {
        // manually remove the bootstrap modal once logout resolves
        // need to pass this callback function otherwise jquery will
        // lose its references to the necessary objects in the DOM
        $(document.body).removeClass("modal-open");
        $(".modal-backdrop").remove();
      }),
    );
  };

  const debug = () => {
    console.debug(location);
    console.debug(location.pathname);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-secondary">
      <div className="container-fluid">
        <a className="navbar-brand pb-0" href="#">
          <img
            src={logoUrl}
            alt="AG Logo"
            className={
              "mx-auto d-inline-top align-text-center " + styles.companyLogo
            }
          />
          {/*Trader Portal*/}
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <BSNavLink
              scopes={scopes}
              scope="dashboard"
              href="/dashboard"
              // href="/counter"
              text="Dashboard"
            />
            <BSNavLink
              scopes={scopes}
              scope="volatility-analysis"
              href="/volatility-analysis"
              text="Volatility Analysis"
            />
            <BSNavLink
              scopes={scopes}
              scope="manage-account"
              href="/manage-account"
              text="Manage Account"
            />
            {/*            <li className="nav-item">
              <a className="nav-link disabled" href="#" tabIndex={-1}>
                Disabled
              </a>
            </li>*/}

            {/*            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
              >
                Dropdown
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </li>*/}
          </ul>
          {/*          <button
            type="button"
            className="btn btn-primary mx-2"
            onClick={() => {
              debug();
            }}
          >
            debug
          </button>*/}
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#logoutModal"
          >
            Logout
          </button>

          {/*          <form className="d-flex">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
            />
            <button className="btn btn-success" type="submit">
              Search
            </button>
          </form>*/}
        </div>
      </div>
      {/*<!-- Modal -->*/}

      <div
        className="modal fade"
        id="logoutModal"
        // data-bs-backdrop="static"
        tabIndex={-1}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Logout
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">Are you sure you want to log out?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                // data-bs-dismiss="modal"
                onClick={() => {
                  handleLogoutClick();
                }}
                disabled={authentication.isLoading}
              >
                {authentication.isLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Yes, Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
